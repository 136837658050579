// preusable/Footer.js
import { useState, useEffect } from 'react';
import Link from 'next/link';

const EditMenu = ({ footerContent, updateFooterContent, onClose }) => (
    <div 
        className={`fixed right-0 top-0 h-full w-96 bg-gray-100 shadow-2xl p-6 overflow-y-auto transition-transform duration-300 ease-in-out transform translate-x-0`}
        style={{transition: 'transform 300ms ease-in-out'}}
    >
        <button 
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
        </button>
        <h2 className="text-2xl font-bold mb-6 text-gray-800">Edit Footer</h2>
        <div className="space-y-6">
            <div className="bg-white p-4 rounded-lg shadow">
                <h3 className="font-semibold mb-3 text-lg text-gray-700">Company Name</h3>
                <input 
                    type="text" 
                    value={footerContent.companyName} 
                    onChange={(e) => updateFooterContent('companyName', e.target.value)}
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent text-black" 
                />
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
                <h3 className="font-semibold mb-3 text-lg text-gray-700">Company Description</h3>
                <textarea 
                    value={footerContent.companyDescription} 
                    onChange={(e) => updateFooterContent('companyDescription', e.target.value)}
                    className="w-full h-32 p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent text-black" 
                />
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
                <h3 className="font-semibold mb-3 text-lg text-gray-700">Footer Color</h3>
                <input 
                    type="color" 
                    value={footerContent.backgroundColor} 
                    onChange={(e) => updateFooterContent('backgroundColor', e.target.value)}
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent" 
                />
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
                <h3 className="font-semibold mb-3 text-lg text-gray-700">Footer Text Color</h3>
                <input 
                    type="color" 
                    value={footerContent.textColor} 
                    onChange={(e) => updateFooterContent('textColor', e.target.value)}
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent" 
                />
            </div>
        </div>
    </div>
);

const Footer = ({ editMode, footerContent, updateFooterContent }) => {
    const [showEditMenu, setShowEditMenu] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        const handleMessage = async (event) => {
            const message = event.data;


            if (message.type === 'SAVE_CHANGES') {
                console.log('Received SAVE_CHANGES message:', message);
                setShowEditMenu(false);
                setIsHovered(false);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <footer 
            className={`py-10 mt-20 relative ${
                editMode && !showEditMenu ? 'group' : ''
            }`}
            style={{
                backgroundColor: footerContent.backgroundColor,
                color: footerContent.textColor
            }}
            onMouseEnter={() => editMode && !showEditMenu && setIsHovered(true)}
            onMouseLeave={() => editMode && !showEditMenu && setIsHovered(false)}
        >
            <div className={`max-w-6xl mx-auto px-4 sm:px-6 ${editMode && !showEditMenu && isHovered ? 'blur' : ''} transition-all duration-300`}>
                <div className="text-center">
                    <Link href="/">
                        <span className={`text-2xl font-bold cursor-pointer ${editMode && !showEditMenu ? 'hover:opacity-80 transition-opacity duration-200' : ''}`}>
                            {footerContent.companyName}
                        </span>
                    </Link>
                    <p className="mt-2 text-sm max-w-md mx-auto opacity-80">
                        {footerContent.companyDescription}
                    </p>
                </div>
                <div className="mt-8 border-t border-opacity-20 pt-8 text-center">
                    <p className="text-sm opacity-80">
                        © {new Date().getFullYear()} {footerContent.companyName}. All rights reserved.
                    </p>
                </div>
            </div>
            {editMode && !showEditMenu && (
                <div className={`absolute inset-0 flex items-center justify-center transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
                    <button 
                        onClick={() => setShowEditMenu(true)}
                        className="bg-black text-white px-2 py-1 hover:bg-opacity-75 transition duration-300 ease-in-out flex items-center space-x-2 text-sm z-10"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                        </svg>
                        <span>Edit Footer</span>
                    </button>
                </div>
            )}
            {showEditMenu && (
                <EditMenu 
                    footerContent={footerContent}
                    updateFooterContent={updateFooterContent}
                    onClose={() => {
                        setShowEditMenu(false);
                        setIsHovered(false);
                    }}
                />
            )}
        </footer>
    );
};

export default Footer;