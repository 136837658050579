// Header.js
import { useState, useEffect } from 'react';

const EditMenu = ({ headerContent, updateHeaderContent, onClose }) => (
    <div 
        className={`fixed z-50 right-0 top-0 h-full bg-gray-100 shadow-2xl p-6 overflow-y-auto transition-transform duration-300 ease-in-out transform translate-x-0`}
        style={{transition: 'transform 300ms ease-in-out', width: '550px'}}
    >
        <button 
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
        </button>
        <h2 className="text-2xl font-bold mb-6 text-gray-800">Edit Header Section</h2>
        <div className="space-y-6">
            <div className="bg-white p-4 rounded-lg shadow">
                <h3 className="font-semibold mb-3 text-lg text-gray-700">Title</h3>
                <label className="flex items-center space-x-3 mb-3">
                    <input 
                        type="checkbox" 
                        checked={headerContent.showTitle} 
                        onChange={(e) => updateHeaderContent('showTitle', e.target.checked)}
                        className="form-checkbox h-5 w-5 text-blue-600" 
                    />
                    <span>Show title</span>
                </label>
                {headerContent.showTitle && (
                    <>
                        <input 
                            type="text" 
                            value={headerContent.title} 
                            onChange={(e) => updateHeaderContent('title', e.target.value)}
                            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent mb-3" 
                        />
                        <div className="flex items-center space-x-2 mb-3">
                            <label className="text-sm font-medium text-gray-700">Title Color:</label>
                            <input 
                                type="color" 
                                value={headerContent.titleColor} 
                                onChange={(e) => updateHeaderContent('titleColor', e.target.value)}
                                className="h-8 w-8"
                            />
                        </div>
                        <div className="flex items-center space-x-2">
                            <label className="text-sm font-medium text-gray-700">Title Size:</label>
                            <input 
                                type="number" 
                                value={headerContent.titleSize} 
                                onChange={(e) => updateHeaderContent('titleSize', e.target.value)}
                                className="w-20 p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                min="16"
                                max="72"
                            />
                        </div>
                    </>
                )}
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
                <h3 className="font-semibold mb-3 text-lg text-gray-700">Subtitle</h3>
                <label className="flex items-center space-x-3 mb-3">
                    <input 
                        type="checkbox" 
                        checked={headerContent.showSubtitle} 
                        onChange={(e) => updateHeaderContent('showSubtitle', e.target.checked)}
                        className="form-checkbox h-5 w-5 text-blue-600" 
                    />
                    <span>Show subtitle</span>
                </label>
                {headerContent.showSubtitle && (
                    <>
                        <input 
                            type="text" 
                            value={headerContent.subtitle} 
                            onChange={(e) => updateHeaderContent('subtitle', e.target.value)}
                            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent mb-3" 
                        />
                        <div className="flex items-center space-x-2 mb-3">
                            <label className="text-sm font-medium text-gray-700">Subtitle Color:</label>
                            <input 
                                type="color" 
                                value={headerContent.subtitleColor} 
                                onChange={(e) => updateHeaderContent('subtitleColor', e.target.value)}
                                className="h-8 w-8"
                            />
                        </div>
                        <div className="flex items-center space-x-2">
                            <label className="text-sm font-medium text-gray-700">Subtitle Size:</label>
                            <input 
                                type="number" 
                                value={headerContent.subtitleSize} 
                                onChange={(e) => updateHeaderContent('subtitleSize', e.target.value)}
                                className="w-20 p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                min="12"
                                max="48"
                            />
                        </div>
                    </>
                )}
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
                <h3 className="font-semibold mb-3 text-lg text-gray-700">Button</h3>
                <label className="flex items-center space-x-3 mb-3">
                    <input 
                        type="checkbox" 
                        checked={headerContent.showButton} 
                        onChange={(e) => updateHeaderContent('showButton', e.target.checked)}
                        className="form-checkbox h-5 w-5 text-blue-600" 
                    />
                    <span>Show button</span>
                </label>
                {headerContent.showButton && (
                    <>
                        <input 
                            type="text" 
                            value={headerContent.buttonText} 
                            onChange={(e) => updateHeaderContent('buttonText', e.target.value)}
                            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent mb-3" 
                            placeholder="Button Text"
                        />
                        <input 
                            type="text" 
                            value={headerContent.buttonLink} 
                            onChange={(e) => updateHeaderContent('buttonLink', e.target.value)}
                            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent mb-3" 
                            placeholder="Button Link (e.g., https://example.com)"
                        />
                        <label className="flex items-center space-x-3 mb-3">
                            <input 
                                type="checkbox" 
                                checked={headerContent.buttonOpenNewTab} 
                                onChange={(e) => updateHeaderContent('buttonOpenNewTab', e.target.checked)}
                                className="form-checkbox h-5 w-5 text-blue-600" 
                            />
                            <span>Open link in new tab</span>
                        </label>
                        <div className="flex items-center space-x-2 mb-3">
                            <label className="text-sm font-medium text-gray-700">Button Color:</label>
                            <input 
                                type="color" 
                                value={headerContent.buttonColor} 
                                onChange={(e) => updateHeaderContent('buttonColor', e.target.value)}
                                className="h-8 w-8"
                            />
                        </div>
                        <div className="flex items-center space-x-2 mb-3">
                            <label className="text-sm font-medium text-gray-700">Button Text Color:</label>
                            <input 
                                type="color" 
                                value={headerContent.buttonTextColor} 
                                onChange={(e) => updateHeaderContent('buttonTextColor', e.target.value)}
                                className="h-8 w-8"
                            />
                        </div>
                        <div className="flex items-center space-x-2">
                            <label className="text-sm font-medium text-gray-700">Button Style:</label>
                            <select
                                value={headerContent.buttonStyle}
                                onChange={(e) => updateHeaderContent('buttonStyle', e.target.value)}
                                className="p-2 w-40 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            >
                                <option value="rounded">Rounded</option>
                                <option value="square">Square</option>
                            </select>
                        </div>
                    </>
                )}
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
                <h3 className="font-semibold mb-3 text-lg text-gray-700">Background</h3>
                <input 
                    type="color" 
                    value={headerContent.backgroundColor} 
                    onChange={(e) => updateHeaderContent('backgroundColor', e.target.value)}
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent" 
                />
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
                <h3 className="font-semibold mb-3 text-lg text-gray-700">Search Box</h3>
                <label className="flex items-center space-x-3 mb-3">
                    <input 
                        type="checkbox" 
                        checked={headerContent.showSearchBox} 
                        onChange={(e) => updateHeaderContent('showSearchBox', e.target.checked)}
                        className="form-checkbox h-5 w-5 text-blue-600" 
                    />
                    <span>Display search box</span>
                </label>
                {headerContent.showSearchBox && (
                    <>
                        <div className="flex items-center space-x-2 mb-3">
                            <label className="text-sm font-medium text-gray-700">Background Color:</label>
                            <input 
                                type="color" 
                                value={headerContent.searchBoxBgColor} 
                                onChange={(e) => updateHeaderContent('searchBoxBgColor', e.target.value)}
                                className="h-8 w-8"
                            />
                        </div>
                        <div className="flex items-center space-x-2 mb-3">
                            <label className="text-sm font-medium text-gray-700">Text Color:</label>
                            <input 
                                type="color" 
                                value={headerContent.searchBoxTextColor} 
                                onChange={(e) => updateHeaderContent('searchBoxTextColor', e.target.value)}
                                className="h-8 w-8"
                            />
                        </div>
                        <div className="flex items-center space-x-2 mb-3">
                            <label className="text-sm font-medium text-gray-700">Border Color:</label>
                            <input 
                                type="color" 
                                value={headerContent.searchBoxBorderColor} 
                                onChange={(e) => updateHeaderContent('searchBoxBorderColor', e.target.value)}
                                className="h-8 w-8"
                            />
                        </div>
                    </>
                )}
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
                <h3 className="font-semibold mb-3 text-lg text-gray-700">Component Border Radius</h3>
                <input 
                    type="number" 
                    value={headerContent.componentBorderRadius} 
                    onChange={(e) => updateHeaderContent('componentBorderRadius', e.target.value)}
                    className="w-20 p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    min="0"
                    max="50"
                />
                <span className="ml-2">px</span>
            </div>
        </div>
    </div>
);

const Header = ({ editMode, headerContent, updateHeaderContent, searchTerm, setSearchTerm, navbarContent, posts=[] }) => {
    const [showEditMenu, setShowEditMenu] = useState(false);
    const [isHeaderHovered, setIsHeaderHovered] = useState(false);

    const getButtonClasses = () => {
        let classes = "inline-block cursor-pointer font-bold py-3 px-6 shadow-lg transform transition duration-300 hover:scale-105 hover:shadow-xl";
        if (headerContent.buttonStyle === 'rounded') {
            classes += " rounded-full";
        }
        return classes;
    };

    useEffect(() => {
        const handleMessage = async (event) => {
            const message = event.data;

            if (message.type === 'SAVE_CHANGES') {
                console.log('Received SAVE_CHANGES message:', message);
                setShowEditMenu(false);
                setIsHeaderHovered(false);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <div 
            className={`max-w-4xl mx-auto ${navbarContent.isSticky ? 'mt-16': '' } px-4 py-8 font-sans relative ${
                editMode && !showEditMenu ? 'group' : ''
            }`}
            style={{
                backgroundColor: headerContent.backgroundColor,
                backgroundImage: `url(${headerContent.backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: `${headerContent.componentBorderRadius}px`,
            }}
            onMouseEnter={() => editMode && !showEditMenu && setIsHeaderHovered(true)}
            onMouseLeave={() => editMode && !showEditMenu && setIsHeaderHovered(false)}
        >
            <div className={`${editMode && !showEditMenu && isHeaderHovered ? 'blur-sm' : ''} transition-all duration-300`}>
                <style>{headerContent.customCSS}</style>
                {headerContent.showTitle && (
                    <h1 className="font-bold text-center mt-10 mb-4"
                        style={{
                            color: headerContent.titleColor,
                            fontSize: `${headerContent.titleSize}px`,
                            // fontWeight: '300'
                        }}
                    >
                        {headerContent.title}
                    </h1>
                )}
                {headerContent.showSubtitle && (
                    <p className="text-center mb-10"
                        style={{
                            color: headerContent.subtitleColor,
                            fontSize: `${headerContent.subtitleSize}px`
                        }}
                    >
                        {headerContent.subtitle}
                    </p>
                )}
                {headerContent.showButton && (
                    <div className="text-center mb-8">
                        <a 
                            href={headerContent.buttonLink || posts.length > 0 && `/blog/${posts[0].slug}`}
                            target={headerContent.buttonOpenNewTab ? "_blank" : "_self"}
                            rel={headerContent.buttonOpenNewTab ? "noopener noreferrer" : ""}
                            className={getButtonClasses()}
                            style={{
                                backgroundColor: headerContent.buttonColor,
                                color: headerContent.buttonTextColor,
                                textDecoration: 'none',
                            }}
                        >
                            {headerContent.buttonText}
                        </a>
                    </div>
                )}

                {headerContent.showSearchBox && (
                    <div className="mb-12">
                        <input
                            type="text"
                            placeholder="Search articles..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full h-16 px-4 text-xl border-2 rounded-lg focus:outline-none focus:border-blue-500 transition-colors duration-300"
                            style={{
                                backgroundColor: headerContent.searchBoxBgColor,
                                color: headerContent.searchBoxTextColor,
                                borderColor: headerContent.searchBoxBorderColor,
                            }}
                        />
                    </div>
                )}
            </div>

            {editMode && !showEditMenu && (
                <div className={`absolute inset-0 flex items-center justify-center transition-opacity duration-300 ${isHeaderHovered ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
                    <button 
                        onClick={() => setShowEditMenu(true)}
                        className="bg-black text-white px-2 py-1 hover:bg-opacity-75 transition duration-300 ease-in-out flex items-center space-x-2 text-sm z-10"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                        </svg>
                        <span>Edit Header Section</span>
                    </button>
                </div>
            )}
            {showEditMenu && (
                <EditMenu 
                    headerContent={headerContent}
                    updateHeaderContent={updateHeaderContent}
                    onClose={() => {
                        setShowEditMenu(false);
                        setIsHeaderHovered(false);
                    }}
                />
            )}
        </div>
    );
};

export default Header;