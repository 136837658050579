import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useRouter } from 'next/router';
import Link from 'next/link';

const excerptLength = 120;

const EditMenu = ({ blogLayout, updateBlogLayout, onClose }) => (
    <div 
        className={`fixed right-0 top-0 h-full w-96 bg-gray-100 shadow-2xl p-6 overflow-y-auto transition-transform duration-300 ease-in-out transform translate-x-0`}
        style={{transition: 'transform 300ms ease-in-out'}}
    >
        <button 
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
        </button>
        <h2 className="text-2xl font-bold mb-6 text-gray-800">Edit Blog Layout</h2>
        <div className="space-y-6">
            <div className="bg-white p-4 rounded-lg shadow">
                <h3 className="font-semibold mb-3 text-lg text-gray-700">Layout Format</h3>
                <select 
                    value={blogLayout.format} 
                    onChange={(e) => updateBlogLayout('format', e.target.value)}
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                >
                    <option value="standard">Standard</option>
                    <option value="featured">Featured First Post</option>
                    <option value="grid">Grid</option>
                    <option value="list">List</option>
                </select>
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
                <h3 className="font-semibold mb-3 text-lg text-gray-700">Display Options</h3>
                <label className="flex items-center space-x-3 mb-2">
                    <input 
                        type="checkbox" 
                        checked={blogLayout.showImages} 
                        onChange={(e) => updateBlogLayout('showImages', e.target.checked)}
                        className="form-checkbox h-5 w-5 text-blue-600" 
                    />
                    <span>Show Images</span>
                </label>
                <label className="flex items-center space-x-3 mb-2">
                    <input 
                        type="checkbox" 
                        checked={blogLayout.showExcerpts} 
                        onChange={(e) => updateBlogLayout('showExcerpts', e.target.checked)}
                        className="form-checkbox h-5 w-5 text-blue-600" 
                    />
                    <span>Show Excerpts</span>
                </label>
                <label className="flex items-center space-x-3">
                    <input 
                        type="checkbox" 
                        checked={blogLayout.showDates} 
                        onChange={(e) => updateBlogLayout('showDates', e.target.checked)}
                        className="form-checkbox h-5 w-5 text-blue-600" 
                    />
                    <span>Show Dates</span>
                </label>
            </div>
            {blogLayout.format === 'grid' && (
                <div className="bg-white p-4 rounded-lg shadow">
                    <h3 className="font-semibold mb-3 text-lg text-gray-700">Grid Columns</h3>
                    <input 
                        type="number" 
                        min="1" 
                        max="4" 
                        value={blogLayout.columns} 
                        onChange={(e) => updateBlogLayout('columns', parseInt(e.target.value))}
                        className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent" 
                    />
                </div>
            )}
            <div className="bg-white p-4 rounded-lg shadow">
                <h3 className="font-semibold mb-3 text-lg text-gray-700">Text Colors</h3>
                <div className="flex items-center space-x-2 mb-3">
                    <label className="text-sm font-medium text-gray-700">Title Color:</label>
                    <input 
                        type="color" 
                        value={blogLayout.titleColor} 
                        onChange={(e) => updateBlogLayout('titleColor', e.target.value)}
                        className="h-8 w-8"
                    />
                </div>
                <div className="flex items-center space-x-2">
                    <label className="text-sm font-medium text-gray-700">Excerpt Color:</label>
                    <input 
                        type="color" 
                        value={blogLayout.excerptColor} 
                        onChange={(e) => updateBlogLayout('excerptColor', e.target.value)}
                        className="h-8 w-8"
                    />
                </div>
            </div>
        </div>
    </div>
);

const BlogLayout = ({ posts, blogLayout, updateBlogLayout, editMode }) => {
    const [showEditMenu, setShowEditMenu] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const router = useRouter();

    useEffect(() => {
        const handleMessage = async (event) => {
            const message = event.data;

            if (message.type === 'SAVE_CHANGES') {
                console.log('Received SAVE_CHANGES message:', message);
                setShowEditMenu(false);
                setIsHovered(false);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    const renderDate = (date) => {
        if (!date || !blogLayout.showDates) {
            return null;
        }

        // Convert the Firestore timestamp to a JavaScript Date object
        const jsDate = new Date(date._seconds * 1000 + date._nanoseconds / 1000000);

        return (
            <p className="text-sm text-gray-500 mt-1">
                {format(jsDate, 'MMMM d, yyyy')}
            </p>
        );
    };

    function truncateTitle(title, wordLimit = 9) {
        const words = title.trim().split(/\s+/);
        
        if (words.length <= wordLimit) {
          return title;
        }
        
        const truncatedWords = words.slice(0, wordLimit);
        return truncatedWords.join(' ') + '...';
    }

    const renderPosts = () => {
        const handleNavigation = async (e) => {
            window.parent.postMessage({ type: 'LOADING'}, '*');
        }

        const linkStyle = {
            textDecoration: 'none',
            color: 'inherit'
        };

        switch (blogLayout.format) {
            case 'featured':
                return (
                    <div className="space-y-6">
                        {posts.length > 0 && (
                            <div className={`bg-white rounded-lg shadow-md overflow-hidden ${editMode && !showEditMenu ? 'transition-shadow duration-300 hover:shadow-xl' : ''}`}>
                                <a style={linkStyle}
                                    href={`/blog/${posts[0].slug}`}
                                    onClick={handleNavigation}
                                >
                                    <div className="cursor-pointer">
                                        {blogLayout.showImages && (
                                            <img 
                                                src={posts[0].featuredImageUrl} 
                                                alt="Featured" 
                                                className="w-full h-64 object-cover"
                                            />
                                        )}
                                        <div className="p-6">
                                            <h2 className={`text-2xl font-semibold ${editMode && !showEditMenu ? 'hover:text-blue-600 transition-colors duration-300' : ''}`} style={{ color: blogLayout.titleColor }}>
                                                {truncateTitle(posts[0].title)}
                                            </h2>
                                            {renderDate(posts[0].date)}
                                            {blogLayout.showExcerpts && (
                                                <p className='my-0 text-md' style={{ color: blogLayout.excerptColor }}>
                                                    {posts[0]?.excerpt.slice(0, 200) + "..." || "Click to read more..."}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </a>
                            </div>
                        )}
                        <ul style={{paddingLeft: '0px'}} className="space-y-6 pl-0">
                            {posts.slice(1).map(post => (
                                <li key={post.slug} className={`bg-white rounded-lg shadow-md overflow-hidden ${editMode && !showEditMenu ? 'transition-shadow duration-300 hover:shadow-xl' : ''}`}>
                                    <a style={linkStyle}
                                        href={`/blog/${post.slug}`}
                                        onClick={handleNavigation}
                                    >
                                        <div className="flex items-center p-4 space-x-4 cursor-pointer">
                                            {blogLayout.showImages && (
                                                <img
                                                    src={post.featuredImageUrl} 
                                                    alt="Featured" 
                                                    className="w-36 h-36 object-cover rounded-md"
                                                />
                                            )}
                                            <div className="flex-1">
                                                <h2 className={`text-xl font-semibold ${editMode && !showEditMenu ? 'hover:text-blue-600 transition-colors duration-300' : ''}`} style={{ color: blogLayout.titleColor }}>
                                                    {truncateTitle(post.title)}
                                                </h2>
                                                <span className="text-xs">{renderDate(post.date)}</span>
                                                {blogLayout.showExcerpts && (
                                                    <p className='my-0 text-md' style={{ color: blogLayout.excerptColor }}>
                                                        {post?.excerpt.slice(0,excerptLength) + "..." || "Click to read more..."}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                );
            case 'grid':
                return (
                    <div className={`grid grid-cols-1 sm:grid-cols-${blogLayout.columns} gap-6`}>
                        {posts.map(post => (
                            <div key={post.slug} className={`bg-white rounded-lg shadow-md overflow-hidden ${editMode && !showEditMenu ? 'transition-shadow duration-300 hover:shadow-xl' : ''}`}>
                                <a style={linkStyle}
                                    href={`/blog/${post.slug}`}
                                    onClick={handleNavigation}
                                >
                                    <div className="cursor-pointer">
                                        {blogLayout.showImages && (
                                            <img 
                                                src={post.featuredImageUrl} 
                                                alt="Featured" 
                                                className="w-full h-48 object-cover"
                                            />
                                        )}
                                        <div className="p-4">
                                            <h2 className={`text-lg font-semibold ${editMode && !showEditMenu ? 'hover:text-blue-600 transition-colors duration-300' : ''}`} style={{ color: blogLayout.titleColor }}>
                                                {truncateTitle(post.title)}
                                            </h2>
                                            <span className="text-xs">{renderDate(post.date)}</span>
                                            {blogLayout.showExcerpts && (
                                                <p className='my-0 text-md' style={{ color: blogLayout.excerptColor }}>
                                                    {post?.excerpt.slice(0,excerptLength) + "..." || "Click to read more..."}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                );
            case 'list':
                return (
                    <ul style={{paddingLeft: '0px'}} className="space-y-6 pl-0">
                        {posts.map(post => (
                            <li key={post.slug} className={`bg-white rounded-lg shadow-md overflow-hidden ${editMode && !showEditMenu ? 'transition-shadow duration-300 hover:shadow-xl' : ''}`}>
                                <a style={linkStyle}
                                    href={`/blog/${post.slug}`}
                                    onClick={handleNavigation}
                                >
                                    <div className="p-4 cursor-pointer">
                                        <h2 className={`text-xl font-semibold ${editMode && !showEditMenu ? 'hover:text-blue-600 transition-colors duration-300' : ''}`} style={{ color: blogLayout.titleColor }}>
                                            {truncateTitle(post.title)}
                                        </h2>
                                        <span className="text-xs">{renderDate(post.date)}</span>
                                        {blogLayout.showExcerpts && (
                                            <p className='my-0 text-md' style={{ color: blogLayout.excerptColor }}>
                                                {post?.excerpt.slice(0,excerptLength) + "..." || "Click to read more..."}
                                            </p>
                                        )}
                                    </div>
                                </a>
                            </li>
                        ))}
                    </ul>
                );
            default: // 'standard'
                return (
                    <ul style={{paddingLeft: '0px'}} className="space-y-6 pl-0">
                        {posts.map(post => (
                            <li key={post.slug} className={`bg-white rounded-lg shadow-md overflow-hidden ${editMode && !showEditMenu ? 'transition-shadow duration-300 hover:shadow-xl' : ''}`}>
                                <a style={linkStyle}
                                    href={`/blog/${post.slug}`}
                                    onClick={handleNavigation}
                                >
                                    <div className="flex items-center p-4 space-x-4 cursor-pointer">
                                        {blogLayout.showImages && (
                                            <img 
                                                src={post.featuredImageUrl} 
                                                alt="Featured" 
                                                className="w-36 h-36 object-cover rounded-md"
                                            />
                                        )}
                                        <div className="flex-1">
                                            <h2 className={`text-xl font-semibold ${editMode && !showEditMenu ? 'hover:text-blue-600 transition-colors duration-300' : ''}`} style={{ color: blogLayout.titleColor }}>
                                            {truncateTitle(post.title)}
                            </h2>
                            <span className="text-xs">{renderDate(post.date)}</span>
                            {blogLayout.showExcerpts && (
                                <p className='my-0 text-md' style={{ color: blogLayout.excerptColor }}>
                                    {post?.excerpt.slice(0,excerptLength) + "..." || "Click to read more..."}
                                </p>
                            )}
                        </div>
                    </div>
                </a>
            </li>
        ))}
    </ul>
);
        }
    };

    return (
        <div 
            className={`max-w-4xl mx-auto px-4 py-8 font-sans relative ${
                editMode && !showEditMenu ? 'group' : ''
            }`}
            onMouseEnter={() => editMode && !showEditMenu && setIsHovered(true)}
            onMouseLeave={() => editMode && !showEditMenu && setIsHovered(false)}
        >
            <div className={`${editMode && !showEditMenu && isHovered ? 'blur' : ''} transition-all duration-300`}>
                {renderPosts()}
            </div>

            {editMode && !showEditMenu && (
                <div className={`absolute inset-0 flex items-center justify-center transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
                    <button 
                        onClick={() => setShowEditMenu(true)}
                        className="bg-black text-white px-2 py-1 hover:bg-opacity-75 transition duration-300 ease-in-out flex items-center space-x-2 text-sm z-10"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                        </svg>
                        <span>Edit Blog Layout</span>
                    </button>
                </div>
            )}
            {showEditMenu && (
                <EditMenu 
                    blogLayout={blogLayout}
                    updateBlogLayout={updateBlogLayout}
                    onClose={() => {
                        setIsHovered(false);
                        setShowEditMenu(false);
                    }}
                />
            )}
        </div>
    );
};

export default BlogLayout;